import datefns from "date-fns";
import Vue from "vue";
import pt from "date-fns/locale/pt";
import dayjs from "dayjs";
import { getCurrentTimezone } from "@/utils/timezone";

Vue.filter("formatDate", (date) => {
  console.log(date);
  return dayjs(date).format("DD/MM/YYYY");
});

Vue.filter("formatDateTime", (date) => {
  return datefns.format(date, "DD/MM/YYYY HH:mm:ss");
});

Vue.filter("formatDateFriendly", (date) => {
  return datefns.format(date, "DD/MM/YYYY");
});

Vue.filter("formatDateTimeFriendly", (date) => {
  return datefns.format(date, "DD/MM/YYYY HH:mm");
});

Vue.filter("distanceOfToday", (date) => {
  return datefns.distanceInWordsToNow(date, { locale: pt });
});

Vue.filter("booleanToString", (boolean) => {
  return boolean ? "Sim" : "Não";
});

Vue.filter("arrayToString", (array) => {
  if (array && array.length > 0) {
    return array
      .map((e) => e.name)
      .join(", ")
      .concat(".");
  } else {
    return "Nenhum(a)";
  }
});

Vue.filter("instructionsArrayToString", (array) => {
  if (array && array.length > 0) {
    return array
      .map((e) => e.description)
      .join(", ")
      .concat(".");
  } else {
    return "Nenhum(a)";
  }
});

Vue.filter("roleToString", (value) => {
  if (value) {
    const mapConditions = new Map([
      ["therapist", "Terapeuta"],
      ["patient", "Paciente"],
    ]);
    return mapConditions.get(value);
  }
});

Vue.filter("formatTime", (value) => {
  if (value) {
    return dayjs(value).format("HH:mm");
  }
});

Vue.filter("formatPointSides", (value) => {
  if (!value) return;
  const mapConditions = new Map([
    ["L", "E"],
    ["R", "D"],
    ["C", "C"],
    ["RL", "D/E"],
    ["RCL", "D/C/E"],
    ["RC", "D/C"],
    ["CL", "C/E"],
  ]);
  return mapConditions.get(value);
});
