<template>
  <new-perseu-card
    title="Confirmação de assinatura"
    render-back-button
    @click-back="$router.go(-1)"
  >
    <v-row justify="center">
      <v-col lg="6" sm="8" cols="12">
        <plan-card-view :plan="selectedPlan" />
      </v-col>
    </v-row>
    <div class="text-center">
      <div class="my-5 body-1">
        Para confirmar a assinatura do plano, faça o pagamento pelo Stripe ou
        Paypal clicando em um dos botões abaixo
      </div>
      <stripe-button :id-plan="selectedPlan.idPaypal" />
    </div>
  </new-perseu-card>
</template>

<script>
import {
  createConfirmedSubscription,
  sendError,
  validateToSubscribe,
} from "@/services/external/paypal-service";

export default {
  name: "Confirmation",
  components: {
    PlanCardView: () =>
      import("@/domains/subscriptions/presentation/components/PlanCardView"),
    StripeButton: () =>
      import("@/domains/subscriptions/presentation/components/StripeButton"),
  },
  computed: {
    selectedPlan() {
      return this.$store.state.subscriptions.selectedPlan;
    },
  },
  mounted() {
    if (!this.selectedPlan.idPaypal) {
      this.$router.push({ name: "subscriptions.plans" });
    }
    this.checkIfUserCanSubscribe();
  },
  methods: {
    async paypalSuccess({ subscriptionId }) {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await createConfirmedSubscription(subscriptionId);
        this.$store.commit("login/set", data);
        this.$successAlert({
          title: "Plano ativado",
          message: "Comece a utilizar as funcionalidades agora mesmo",
        });
        this.$router.push({ name: "home" });
      } catch (error) {
        await sendError(error.stack);
        this.$warningAlert({
          message:
            "Seu pagamento foi realizado, mas algo aconteceu de errado. Já reportamos o erro para que seja solucionado o mais breve possível.",
        });
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async paypalError(error) {
      await sendError(error.stack ? error.stack : error);
      this.$errorAlert({
        title: "Erro ao realizar pagamento",
        message:
          "O pagamento não foi realizado. Tente novamente mais tarde. Já reportamos o erro para que seja solucionado o mais breve possível.",
      });
      this.$router.push({ name: "subscriptions.plans" });
    },
    paypalCancelled() {
      this.$warningAlert({
        message: "Você cancelou o pagamento do plano. Pagamento não realizado.",
      });
    },
    async checkIfUserCanSubscribe() {
      try {
        await validateToSubscribe();
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message || "Erro ao validar nova assinatura.";
        this.$errorAlert({ message: errorMessage });
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>

<style scoped></style>
