<template>
  <view-tracking-impaction-point v-bind="$props" class="pb-5">
    <template #trackingPointContainer>
      <div class="px-8">
        <v-checkbox
          v-if="trackingPoint.hasSides"
          v-model="trackingPointSideArray"
          hide-details
          label="Direito"
          value="R"
          @change="emitInput"
        />
        <v-checkbox
          v-if="trackingPoint.hasACentral"
          v-model="trackingPointSideArray"
          hide-details
          label="Central"
          value="C"
          @change="emitInput"
        />
        <v-checkbox
          v-if="trackingPoint.hasSides"
          v-model="trackingPointSideArray"
          hide-details
          label="Esquerdo"
          value="L"
          @change="emitInput"
        />
      </div>
    </template>
    <template #impactionPointContainer>
      <div class="px-8">
        <v-checkbox
          v-if="impactionPoint.hasSides"
          v-model="impactionPointSideArray"
          hide-details
          label="Direito"
          value="R"
          @change="emitInput"
        />
        <v-checkbox
          v-if="impactionPoint.hasACentral"
          v-model="impactionPointSideArray"
          hide-details
          label="Central"
          value="C"
          @change="emitInput"
        />
        <v-checkbox
          v-if="impactionPoint.hasSides"
          v-model="impactionPointSideArray"
          hide-details
          label="Esquerdo"
          value="L"
          @change="emitInput"
        />
      </div>
    </template>
  </view-tracking-impaction-point>
</template>

<script>
import { transformSidesArrayToString } from "@/utils/points";

export default {
  name: "TrackingImpactionPointInputSides",
  props: {
    impactionPoint: {
      type: Object,
      default: () => ({
        name: "",
        image: "",
        hasACentral: false,
      }),
    },
    trackingPoint: {
      type: Object,
      default: () => ({
        name: "",
        image: "",
        hasACentral: false,
      }),
    },
    justify: {
      type: String,
      default: "center",
    },
    value: {
      type: Object,
      default: ({ trackingPointSide, impactionPointSide }) => {
        if (!trackingPointSide || !impactionPointSide) {
          this.trackingPointSideArray = [];
          this.impactionPointSideArray = [];
          return {};
        }
      },
    },
  },
  data: () => ({
    trackingPointSideArray: [],
    impactionPointSideArray: [],
  }),
  methods: {
    emitInput() {
      const { trackingPointSides, impactionPointSides } = this.organizeLetter();
      console.log(trackingPointSides, impactionPointSides);
      this.$emit("input", {
        trackingPointSide: trackingPointSides?.join("") || null,
        impactionPointSide: impactionPointSides?.join("") || null,
      });
    },
    organizeLetter() {
      return {
        trackingPointSides: transformSidesArrayToString(
          this.trackingPointSideArray
        ),
        impactionPointSides: transformSidesArrayToString(
          this.impactionPointSideArray
        ),
      };
    },
    clear() {
      this.trackingPointSideArray = [];
      this.impactionPointSideArray = [];
    },
  },
};
</script>

<style scoped></style>
