<template>
  <div>
    <v-divider class="my-5" />
    <label-icon label="Tipos de fluxo" icon="bubble_chart" bold />
    <div v-if="hasFlowTypes">
      <v-list two-line>
        <v-list-group
          v-for="(ft, indexFt) in diagnose.flowTypes"
          :key="indexFt"
          class="elevation-1"
          @click="selectIndex(indexFt)"
        >
          <template v-slot:activator>
            <v-list-item class="pa-7">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ ft.flowType.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Zona de resolução:
                  {{ ft.diagnoseResolutionZone.resolutionZone.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div>
            <tracking-impaction-point-view-sides
              class="grey"
              :tracking-point="
                ft.diagnoseResolutionZone.resolutionZone.trackingPoint
              "
              :impaction-point="
                ft.diagnoseResolutionZone.resolutionZone.impactionPoint
              "
              :tracking-point-side="ft.diagnoseResolutionZone.trackingPointSide"
              :impaction-point-side="
                ft.diagnoseResolutionZone.impactionPointSide
              "
            />
            <div class="d-flex flex-column grey lighten-3">
              <view-point
                label="Par trauma"
                :name="
                  ft.diagnoseResolutionZone.resolutionZone.impactionPoint.name
                "
                :image="
                  ft.diagnoseResolutionZone.resolutionZone.impactionPoint.image
                "
                :image-description="
                  ft.diagnoseResolutionZone.resolutionZone.impactionPoint
                    .imageDescription
                "
                :expand-image="true"
              />
              <div class="d-flex justify-center">
                <property-value
                  property="Lado(s) selecionado(s)"
                  :value="
                    ft.diagnoseResolutionZone.parTraumaSide | formatPointSides
                  "
                />
              </div>
            </div>

            <v-row class="px-10 pt-10 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="ft.diagnoseResolutionZone.affectedSpotParTrauma"
                label="Ponto afetado - Par Trauma"
                return-value="newValue"
                @save="changeAffectedSpotParTrauma"
              />
            </v-row>
            <v-row class="px-10 py-3 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="ft.therapistComments"
                label="Comentários do terapeuta (não será exibido para o paciente)"
                return-value="newValue"
                @save="changeProperties"
              />
            </v-row>
            <v-row class="px-10 py-3 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="ft.patientComments"
                label="Comentários
              ao paciente"
                return-value="newValue"
                @save="changeProperties"
              />
            </v-row>
            <v-row justify="center" class="py-5">
              <v-btn dark color="red" @click="removeFlowType(indexVp)">
                <v-icon class="mr-2">delete</v-icon>
                Remover
              </v-btn>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <div v-else class="mt-5">Nenhum tipo de fluxo adicionado.</div>
  </div>
</template>

<script>
export default {
  name: "FlowTypesSection",
  components: {
    TextWithEditor: () => import("@/components/shared/TextWithEditor"),
  },

  data: () => ({
    selectedIndex: -1,
  }),
  computed: {
    hasFlowTypes() {
      return this.$store.getters["diagnosis/hasFlowTypes"];
    },
    diagnose() {
      return this.$store.getters["diagnosis/diagnose"];
    },
  },
  methods: {
    removeFlowType(index) {
      const ok = confirm(
        "Tem certeza que deseja remover este Topo de Fluxo do diagnóstico?"
      );
      if (ok) this.$store.commit("diagnosis/removeFlowType", index);
    },
    changeProperties(value) {
      this.$store.commit("diagnosis/changePropertyFlowType", {
        index: this.indexSelected,
        property: value,
      });
    },
    changeAffectedSpotParTrauma({ newValue }) {
      this.$store.commit("diagnosis/changeAffectedSpotParTraumaFlowType", {
        index: this.selectedIndex,
        newValue,
      });
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
  },
};
</script>

<style></style>
