<template>
  <v-form ref="form">
    <v-dialog v-if="dialog" v-model="dialog" max-width="1000px" persistent>
      <perseu-card title="Zonas de Resolução">
        <template slot="title-left">
          <back-button color="grey" @click="close" />
        </template>
        <template slot="content">
          <p class="black--text subtitle-1">
            <v-icon>chevron_right</v-icon>
            Verifique a zona de resolução e selecione em caso de resposta
            afirmativa:
          </p>
          <v-list>
            <v-list-group
              v-for="(resolutionZone, indexRz) in resolutionZones"
              :key="indexRz"
              class="elevation-2 mb-3"
            >
              <template v-slot:activator>
                <v-list-item-title
                  class="py-10 px-5 subtitle-1 font-weight-bold"
                >
                  {{ resolutionZone.name }}
                </v-list-item-title>
              </template>
              <div>
                <v-divider class="mb-3" />
                <div class="black--text d-flex align-center mb-3">
                  <v-icon>chevron_right</v-icon>
                  <span class="subtitle-2 font-weight-bold">
                    1 - Impacte o par de resolução:
                  </span>
                </div>
                <tracking-impaction-point-input-sides
                  v-model="pointsSides"
                  :tracking-point="resolutionZone.trackingPoint"
                  :impaction-point="resolutionZone.impactionPoint"
                />
                <v-divider class="my-3" />
                <div
                  class="d-flex align-center subtitle-2 font-weight-bold black--text mb-5"
                >
                  <v-icon>chevron_right</v-icon>
                  2 - Encontre o local afetado dentro da zona de Resolução e
                  impacte o Par Trauma
                </div>
                <v-row class="px-2">
                  <v-col
                    md="6"
                    cols="12"
                    class="text-center px-10 align-self-center"
                  >
                    <image-expansion
                      title="Área para encontrar zona de resolução"
                      :src="resolutionZone.parTraumaAreaImage"
                      class="mb-5"
                    />
                    <v-text-field
                      v-model="affectedSpotParTrauma"
                      rounded
                      outlined
                      maxlength="255"
                      label="Ponto de Rastreio encontrado"
                      :rules="[$rules.required, $rules.maxLengthVarchar]"
                    />
                  </v-col>
                  <v-col md="6" cols="12" class="px-10">
                    <view-point
                      v-model="parTraumaSide"
                      show-sides
                      :image="resolutionZone.impactionPoint.image"
                      :label="resolutionZone.impactionPoint.name"
                      :name="resolutionZone.impactionPoint.name"
                      :has-a-central-side="
                        resolutionZone.impactionPoint.hasACentral
                      "
                    />
                  </v-col>
                </v-row>
                <div class="d-flex flex-column pa-3">
                  <v-btn
                    color="secondary"
                    large
                    @click="addToDiagnose(resolutionZone)"
                  >
                    Adicionar ao diagnóstico
                  </v-btn>
                </div>
              </div>
            </v-list-group>
          </v-list>
        </template>
      </perseu-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    commentsDialog: false,
    diagnoseResolutionZone: {},
    affectedSpotParTrauma: "",
    pointsSides: {},
    parTraumaSide: "",
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.resolutionZones;
      },
      set(value) {
        this.$store.commit("dialogs/setResolutionZones", value);
      },
    },
    resolutionZones() {
      return this.$store.state.resolutionZones.resolutionZones;
    },
    /* selectedResolutionZones() {
      return this.$store.state.tumoralPhenomena.selectedResolutionZones;
    }, */
  },
  created() {
    this.$store.dispatch("resolutionZones/load");
  },
  methods: {
    async addToDiagnose(resolutionZone) {
      this.diagnoseResolutionZone = {
        resolutionZone,
        therapistComments,
        patientComments,
        affectedSpotParTrauma: this.affectedSpotParTrauma,
        trackingPointSide: this.pointsSides.trackingPointSide,
        impactionPointSide: this.pointsSides.impactionPointSide,
        parTraumaSide: this.parTraumaSide,
      };

      if (!(await this.validateToAdd(resolutionZone))) return;

      const { therapistComments, patientComments } = await this.$store.dispatch(
        "settings/openComments"
      );

      console.log(therapistComments);
      console.log(patientComments);

      await this.$store.dispatch("resolutionZones/callReturnAction", {
        ...this.diagnoseResolutionZone,
        therapistComments,
        patientComments,
      });

      this.$toasted.global.success({
        message: "Adicionado com sucesso ao diagnóstico.",
      });

      this.close();
    },

    async validateToAdd(selectedResolutionZone) {
      if (!this.$refs.form.validate()) {
        return false;
      }

      const { impactionPointSide, trackingPointSide } = this.pointsSides;
      const {
        trackingPoint: { hasSides: trackingPointHasSides },
        impactionPoint: { hasSides: impactionPointHasSides },
      } = selectedResolutionZone;

      if (trackingPointHasSides && !trackingPointSide) {
        this.$toasted.global.error({
          message: "Obrigatório escolher os lados do PONTO DE RASTREIO",
        });
        return false;
      }

      if (impactionPointHasSides && !impactionPointSide) {
        this.$toasted.global.error({
          message: "Obrigatório escolher os lados do PONTO DE IMPACTAÇÃO",
        });
        return false;
      }

      if (!this.parTraumaSide) {
        this.$toasted.global.error({
          message:
            "Obrigatório escolher o lado do ponto de impactação do Par Trauma",
        });
        return false;
      }

      try {
        await this.$store.dispatch(
          "resolutionZones/callValidateAction",
          this.diagnoseResolutionZone
        );
      } catch (error) {
        this.$toasted.global.error({ message: error.message });
        return false;
      }

      return true;
    },
    async close() {
      this.$store.commit("resolutionZones/clearActions");
      this.expanded = [];
      this.affectedSpotParTrauma = "";
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
