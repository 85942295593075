import diagnosisTumoralPhenomenaModule from "./diagnosis-tumoral-phenomena.module";

const initialState = () => ({
  diagnose: {
    hideNamesChakras: false,
    pares: [],
    vascularPhenomena: [],
    potentialSpaces: [],
    checkedCategoriesTumoralPhenomena: null,
    checkedCategoriesPotentialSpaces: null,
    flowTypes: [],
    oligoelements: [],
    chakras: [],
    chromosomes: [],
    therapistComments: "",
    patientComments: "",
    complementarTherapies: [],
  },
});

const state = initialState();

const mutations = {
  clearDiagnose(state) {
    Object.assign(state, initialState());
  },
  addParToDiagnose(state, diagnosePar) {
    state.diagnose.pares.push(diagnosePar);
  },
  changeParProperties(state, { newPar, index }) {
    const currentPar = state.diagnose.pares[index];
    const changedPar = Object.assign(currentPar, newPar);
    state.diagnose.pares.splice(index, 1, changedPar);
  },
  removePar(state, index) {
    state.diagnose.pares.splice(index, 1);
  },
  setDiagnose(state, newDiagnose) {
    state.diagnose = newDiagnose;
  },
  setVascularPhenomena(state, vascularPhenomena) {
    state.diagnose.vascularPhenomena = vascularPhenomena;
  },
  addVascularPhenomena(state, diagnoseVascularPhenomena) {
    state.diagnose.vascularPhenomena.push(diagnoseVascularPhenomena);
  },
  addPotentialSpace(state, diagnosePotentialSpace) {
    state.diagnose.potentialSpaces.push(diagnosePotentialSpace);
  },
  addFlowType(state, diagnoseFlowType) {
    state.diagnose.flowTypes.push(diagnoseFlowType);
  },
  addOligoelement(state, diagnoseOligoelement) {
    state.diagnose.oligoelements.push(diagnoseOligoelement);
  },
  addChakra(state, diagnoseChakra) {
    state.diagnose.chakras.push(diagnoseChakra);
  },
  addChromosome(state, diagnoseChromosome) {
    state.diagnose.chromosomes.push(diagnoseChromosome);
  },
  removeVascularPhenomenon(state, index) {
    state.diagnose.vascularPhenomena.splice(index, 1);
  },
  removePotentialSpace(state, index) {
    state.diagnose.potentialSpaces.splice(index, 1);
  },
  removeFlowType(state, index) {
    state.diagnose.flowTypes.splice(index, 1);
  },
  removeOligoelement(state, index) {
    state.diagnose.oligoelements.splice(index, 1);
  },
  removeChakra(state, index) {
    state.diagnose.chakras.splice(index, 1);
  },
  removeChromosome(state, index) {
    state.diagnose.chromosomes.splice(index, 1);
  },
  addComplementaryTherapy(state, complementaryTherapy) {
    state.diagnose.complementarTherapies.push(complementaryTherapy);
  },
  removeComplementaryTherapy(state, index) {
    state.diagnose.complementarTherapies.splice(index, 1);
  },

  setTherapistComments(state, value) {
    state.diagnose.therapistComments = value;
  },
  setPatientComments(state, value) {
    state.diagnose.patientComments = value;
  },
  setCheckedCategoriesPotentialSpaces(state, value) {
    state.diagnose.checkedCategoriesPotentialSpaces = value;
  },
  changeAffectedSpotParTraumaVascularPhenomenon(state, { index, newValue }) {
    state.diagnose.vascularPhenomena[
      index
    ].diagnoseResolutionZone.affectedSpotParTrauma = newValue;
  },

  changeAffectedSpotParTraumaPotentialSpace(state, { index, newValue }) {
    state.diagnose.potentialSpaces[
      index
    ].diagnoseResolutionZone.affectedSpotParTrauma = newValue;
  },
  changeAffectedSpotParTraumaFlowType(state, { index, newValue }) {
    state.diagnose.flowTypes[
      index
    ].diagnoseResolutionZone.affectedSpotParTrauma = newValue;
  },
  changePropertyFlowType(state, { index, property }) {
    Object.assign(state.diagnose.flowTypes[index], property);
  },
  changePropertyOligoelements(state, { index, property }) {
    Object.assign(state.diagnose.oligoelements[index], property);
  },
  changePropertyChakras(state, { index, property }) {
    Object.assign(state.diagnose.chakras[index], property);
  },
  changePropertyChromosomes(state, { index, property }) {
    Object.assign(state.diagnose.chromosomes[index], property);
  },
  changePropertyComplementaryTherapy(state, { index, property }) {
    Object.assign(state.diagnose.complementarTherapies[index], property);
  },
  changePropertyTumoralPhenomena(state, { index, property }) {
    Object.assign(state.diagnose.tumoralPhenomena[index], property);
  },
  changePropertyVascularPhenomena(state, { index, property }) {
    Object.assign(state.diagnose.vascularPhenomena[index], property);
  },
  setHideNamesChakras(state, value) {
    state.diagnose.hideNamesChakras = value;
  },
};

const actions = {
  async addParToDiagnose({ commit, dispatch }, diagnosePar) {
    try {
      await dispatch("validateIfExistsParInDiagnosis", diagnosePar.id);
      commit("addParToDiagnose", diagnosePar);
    } catch (error) {
      throw error;
    }
  },
  async validateIfExistsParInDiagnosis({ state, dispatch }, parId) {
    return new Promise(async (resolve, reject) => {
      const exists = await dispatch("verifyIfExistsParInDiagnosis", parId);
      if (exists) {
        reject({ message: "Este par já está adicionado ao diagnóstico." });
      } else {
        resolve();
      }
    });
  },
  verifyIfExistsParInDiagnosis({ state }, parId) {
    return state.diagnose.pares.some(
      (diagnosePar) => diagnosePar.par.id === parId
    );
  },
  findCategoriesOfPares({ state }) {
    const categoriesPares = state.diagnose.pares.map(
      (parDiagnose) => parDiagnose.par.categories
    );
    return categoriesPares.flat(Infinity);
  },

  addRelatedParesToAddedPotentialSpace({ state }, { id, relatedParesIds }) {
    const index = state.diagnose.potentialSpaces.findIndex(
      (diagnosePotentialSpace) =>
        diagnosePotentialSpace.potentialSpace.id === id
    );
    state.diagnose.potentialSpaces[index].relatedPares = relatedParesIds;
  },
};

const getters = {
  diagnose(state, getters, rootState) {
    return {
      tumoralPhenomena: rootState.diagnosis.tumoralPhenomena.added,
      ...state.diagnose,
    };
  },
  hasVascularPhenomena(state) {
    return state.diagnose.vascularPhenomena.length > 0;
  },
  hasPares(state) {
    return state.diagnose.pares.length > 0;
  },
  hasPotentialSpaces(state) {
    return state.diagnose.potentialSpaces.length > 0;
  },
  hasFlowTypes(state) {
    return state.diagnose.flowTypes.length > 0;
  },
  hasOligoelements(state) {
    return state.diagnose.oligoelements.length > 0;
  },
  hasChakras(state) {
    return state.diagnose.chakras.length > 0;
  },
  hasChromosomes(state) {
    return state.diagnose.chromosomes.length > 0;
  },
  hasComplementaryTherapies(state) {
    return state.diagnose.complementarTherapies.length > 0;
  },

  categoriesPotentialSpaces(state) {
    return state.diagnose.potentialSpaces.map((ps) => ps.categories);
  },
  addedPares(state) {
    return state.diagnose.pares;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    tumoralPhenomena: diagnosisTumoralPhenomenaModule,
  },
};
