<template>
  <div>
    <label-icon
      icon="healing"
      label="Pares impactados (selecione para editar ou remover)"
      bold
    />
    <v-list v-if="hasPares" class="elevation-1 mt-3">
      <v-list-group
        v-for="(item, index) in diagnose.pares"
        :key="index"
        @click="loadPointsImages(item.par)"
      >
        <template v-slot:activator>
          <v-list-item class="py-2 item-par">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.par.type.name }}: {{ item.par.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <div class="subheading black--text">
          <tracking-impaction-point-view-sides
            v-if="selected.trackingPoint && selected.impactionPoint"
            :tracking-point="selected.trackingPoint"
            :impaction-point="selected.impactionPoint"
            :tracking-point-side="item.trackingPointSide"
            :impaction-point-side="item.impactionPointSide"
          />
          <div class="inline-edit-items ml-3 mt-2">
            <property-value property="Tipo" :value="item.par.type.name" />
            <switch-editor
              :value="item.hidePatient"
              label="Esconder ao paciente na diagnose"
              return-value="hidePatient"
              @save="changeParProperties"
              @open="selectIndex(index)"
            />
            <switch-editor
              :value="item.patientImpactAtHome"
              label="Recomendar ao paciente impactar em casa"
              return-value="patientImpactAtHome"
              @save="changeParProperties"
              @open="selectIndex(index)"
            />
            <text-editor
              :value="item.therapistComments"
              label="Comentários do terapeuta (não será exibido para o paciente)"
              return-value="therapistComments"
              @save="changeParProperties"
              @open="selectIndex(index)"
            />
            <text-editor
              :value="item.patientComments"
              label="Comentários ao paciente"
              return-value="patientComments"
              @save="changeParProperties"
              @open="selectIndex(index)"
            />
            <select-items-editor
              :value="item.symptoms"
              :items="selected.symptoms"
              return-value="symptoms"
              label="Sintomas"
              @save="changeParProperties"
              @open="selectIndex(index)"
            />
            <select-items-editor
              :value="item.pathogens"
              :items="selected.pathogens"
              return-value="pathogens"
              label="Patógenos"
              @save="changeParProperties"
              @open="selectIndex(index)"
            />
          </div>
          <v-layout class="mt-5 mb-2" justify-center>
            <v-btn color="error" @click="removePar(index, item.par.id)">
              <v-icon class="mr-2">delete</v-icon>
              Remover
            </v-btn>
          </v-layout>
        </div>
      </v-list-group>
    </v-list>
    <div v-else class="mt-5">Nenhum par adicionado.</div>
  </div>
</template>

<script>
import { findOne as findOnePar } from "@/services/local/pares-biomagneticos-service";
import { findOne as findOnePoint } from "@/services/local/points-service";
import { findByPar } from "@/services/local/points-order-service";

export default {
  data: () => ({
    selectedIndex: 0,
    selected: {
      trackingPoint: {},
      impactionPoint: {},
    },
  }),
  computed: {
    diagnose() {
      return this.$store.getters["diagnosis/diagnose"];
    },
    hasPares() {
      return this.$store.getters["diagnosis/hasPares"];
    },
  },
  methods: {
    async removePar(index, parId) {
      const ok = confirm(
        "Tem certeza que deseja remover este PAR do diagnóstico?"
      );
      if (ok) {
        await this.$store.dispatch("points/changeFieldAddedToDiagnosis", {
          parId,
          isAdded: false,
        });
        this.$store.commit("diagnosis/removePar", index);
      }
    },
    changeParProperties(newValue) {
      this.$store.commit("diagnosis/changeParProperties", {
        newPar: newValue,
        index: this.selectedIndex,
      });
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
    diagnoseParHasSymptomsAndPathogens(item) {
      return item.pathogens && item.symptoms;
    },
    async loadPointsImages({ id: parId }) {
      this.selected = {
        trackingPoint: {},
        impactionPoint: {},
      };
      const [populatedSelectedPar, pointOrder] = await Promise.all([
        findOnePar(parId),
        findByPar(parId),
      ]);

      this.selected = {
        impactionPoint: await findOnePoint(pointOrder.impactionPointId),
        trackingPoint: await findOnePoint(pointOrder.trackingPointId),
        symptoms: populatedSelectedPar.symptoms,
        pathogens: populatedSelectedPar.pathogens,
      };
    },
  },
};
</script>

<style scoped>
.inline-edit-items > * {
  margin-top: 20px;
}
</style>
